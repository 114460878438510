/* eslint-disable react/no-danger, react/prop-types */
import React from 'react'
// import { shape } from 'prop-types'
import { useLocation } from "@reach/router"
import { graphql } from 'gatsby'
import { motion } from 'framer-motion'
import SEO from '@/components/Seo'
import { fade } from '@/utils/transitions'
import { cleanProps, scrollToForm } from '@/utils'
import Blocks from '@/utils/Blocks'
import useConstant from '@/hooks/useConstant'
import { Hero } from '@/components/Hero'
import Form from '@/components/Form'
// import * as T from '@/types'

function Product({
	pageContext: { media, wordpress_id },
	data: {
		page: { title, blocks, yoast, featured_media, path }
	}
}) {
	const location = useLocation()
	if(location.hash === '#form-block') scrollToForm();

    const data = useConstant(() => cleanProps(blocks, media))
	const hero = data.find(block => block.type === 'acf/hero')
	const remainingBlocks = data.filter(block => block.type !== 'acf/hero').map(block => {
		if(block.type === 'acf/tabs') {
			return {
				pageTitle: title,
				...block
			}
		}
		return block
	})
	
    return (
		<motion.div
			variants={fade}
			initial="initial"
			animate="enter"
			exit="exit"
		>
			<SEO {...yoast} image={featured_media} />
			<>
				<div className="pb-4">
					<Hero {...hero} />
				</div>
				<Blocks data={remainingBlocks} media={media} currentPage={wordpress_id} />
			</>
		</motion.div>
	)
}

// Product.propTypes = {
// 	pageContext: T.pageContext.isRequired,
// 	data: shape({
// 		page: T.page.isRequired
// 	})
// }

export const query = graphql`
	query ProductQuery($path: String!) {
		page: wordpressWpProducts(path: { eq: $path }) {
			...productQuery
		}
	}
`

export default Product
